/* stylelint-disable */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;

    font-family: inherit;
    font-style: inherit;
    font-size: inherit;
    font-weight: inherit;

    border: 0;
    border-radius: 0;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
} 

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button,
input,
textarea,
select {
    font-family: inherit;
    font-style: inherit;
    font-size: inherit;
    font-weight: inherit;
    background-color: transparent;
    border: none;
    color: inherit; 
    appearance: none; 
}

/* stylelint-enable */

/* stylelint-disable selector-max-type  */
@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

html,
body { 
    overflow-x: hidden; 
    margin: 0;   
    padding: 0;
    cursor: default;
} 

body {
    background-color: var(--theme-background-color); 
}

html {
    -webkit-overflow-scrolling: touch;  
    overscroll-behavior: none; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
    box-sizing: border-box;
} 

img {
    display: block;
    max-width: 100%;
    height: auto;
} 

a {
    color: inherit;
    text-decoration: none;
}

svg {
    fill: currentColor; 
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
}  

.visually-hidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	padding:0 !important;
	border:0 !important;
	height: 1px !important; 
	width: 1px !important; 
	overflow: hidden;
}

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
}

body:not(.user-is-tabbing) *:focus {
    outline: none !important;
} 
 
button, a {
    user-select: none; 
}
