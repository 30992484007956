@import "../../assets/style/mixins.scss";

.part-one {
    display: flex;  
    position: relative;
    flex-wrap: wrap;
    padding-top: 2em;

    @media (max-width:600px) { 
        margin-top: 2em;
    } 

    @media (max-width:500px) {
        padding-top: 1em;
    }

    &__border {
        height: 4px;
        width: 100%;
        position: absolute;
        top:0;
        transition: 1.25s ease-out;
        left:0;
        transform-origin: 0 50%;
        transform: scaleX(0);
        background-color: var(--theme-border-hi-color); 
        
        @media (max-width: 900px) {
            transform: scale(1) !important; 
        }

        &--active {
            transform: scaleX(1);
        }
    }

    &__num {
        color: var(--theme-text-color-primary);   
        position: absolute;
        top: 2rem;
        left: 0;
        
        @media (max-width: 500px) {
            top: 1rem;
        }
    } 

    &__title {
        margin-left: grid(3) + grid-gap();
        flex: 0 0 grid(4); 
        color: var(--theme-text-color-primary);  

        @media (max-width:1100px) {
            flex-basis: grid(5); 
        }

        @media (max-width:900px) {
            flex-basis: grid(6); 
        }

        @media (max-width: 500px) {
            flex-basis: grid(7); 
        } 
    }

    &__text { 
        margin-left: grid(1) + grid-gap();
        flex: 0 0 grid(4);  

        @media (max-width:1100px) {
            margin-left: grid(3) + grid-gap();
            flex-basis: grid(8); 
            margin-top: (100/18) + em; 
        }    

        @media (max-width:850px) { 
            margin-top: (50/18) + em; 
        }  

        @media (max-width:500px) {
            flex-basis: grid(12);
            margin-top: (48/18) + em; 
            margin-left: 0;
        }
    }

    &__image { 
        flex: 0 0 100%;
        margin-top: 3em; 
    }
}