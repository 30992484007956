@import "../../assets/style/mixins.scss";

.hero {
    position: relative;
    height: 100vh; 
    margin-bottom: 10em; 
    transition: height .25s;

    @media (max-width: 1200px) {
        margin-bottom: 7em;
    }

    @media (max-width: 900px) { 
        height: 85vh; 
    }

    @media (max-width: 800px) { 
        margin-bottom: 4em;
    }

    &__logo {
        width: 95%;
        margin-bottom: 5vw;
        margin-left: auto;
        margin-right: auto;
        color: var(--theme-hero-logo-color);

        @media (max-width: 900px) { 
            margin-bottom: 15vw;
        }
    }

    &__title {
        width: 90%;
        margin-top: 20vh;
        margin-left: grid(3) + grid-gap();
        max-width: 5em;
        color: var(--theme-hero-title-color); 

        @media (max-width: 800px) { 
            margin-top: 30vh;
        }
    }

    &__overlay {
        position: absolute;
        top:0;
        left:0;
        display: block; 
        color: var(--theme-graphic-color);    
        pointer-events: none;
        touch-action: none;
        overflow: hidden;
        object-fit: cover; 

        &--static {
            height: 100%;

            @media (max-width: 500px) { 
                height: 90%; 
            }
        }

        &--grid { 
            height: 130%;
            width: 100%; 

            @media (max-width: 500px) { 
                height: 120%; 
            }
        }        
        
        &--spline { 
            height: 150%;
            width: 100%; 

            @media (max-width: 1000px) { 
                height: 130%; 
            }

            @media (max-width: 500px) { 
                height: 135%; 
            }
        } 
    }

    &__content {
        height: 100vh; 
        justify-content: space-between;
        display: flex;
        flex-direction: column; 
        
        @media (max-width: 900px) { 
            height: 85vh; 
        }
    }
}
 