@import "../../assets/style/mixins.scss";

.header {
    position: fixed;
    top: 0;
    z-index: 100;
    left: 0;
    right: 0;
    padding-top: 2.5em;
    color: #FFF;

    @media (max-width:700px) {
        padding-top: 1.5em;
    }

    strong {
        font-weight: bold;
    }


    &__inner {
        display: flex;
        align-items: center;

        @media (max-width:700px) {
            flex-wrap: wrap;
        }
    }

    &__get-in-touch {
        order:1;
        display: block;
        flex: 0 0 grid(3) + grid-gap();
        white-space: nowrap;
        font-weight: bold;
        margin-top: -.5em;

        a {
            padding: .5em .5em;
            border-radius: .25em;
            margin-left: -.5em;
        }

        svg {
            width: .75em;
            display: inline-block;
            margin-left: .35em;
            top: .15em;
            position: relative;
        }

        @media (max-width:700px) {
            order: 2;
            flex: 0;
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__logo {
        order:2;
        flex: 0 0 grid(3); 

        svg {
            width: 116/18 + em;
        }

        @media (max-width:700px) {
            order: 1;
            margin-left: grid(3) + grid-gap(); 
        }

        @media (max-width:450px) {
            order: 1;
            margin-left: 0;
            flex-basis: 85px;
        }
    }

    // no longer in use
    &__career {
        order:3;
        flex: 0 0 grid(3);
        margin-left: auto;

        @media (max-width:700px) {
            flex-basis: 100%;
            margin-left: grid(3) + grid-gap();
            margin-top: .25em;
            display: none;
        }

        @media (max-width:450px) {
            margin-left: 0;
        }
    }

    &__noa {
        order:4;
        flex: 0 0 grid(3);
        margin-left: auto;
        margin-right: 0;
        text-align: right;

        @media (max-width:700px) {
            display: none;
        }
    }
}
