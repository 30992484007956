.image { 
    position: relative;
    --full-opacity: 1; 
    transition: opacity .35s;
    opacity: 0;

    &__src {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        z-index: 0;
        height: auto;
    } 

    &--loaded {
        opacity: var(--full-opacity);
    }
}