@import "../../assets/style/mixins.scss";

.intro {
    display: flex;
    margin-top: 128/18 + em;
    margin-bottom: 128/18 + em;
    flex-wrap: wrap;

    @media (max-width:1000px) {
        margin-top: 85/18 + em;
        margin-bottom: 85/18 + em;
    }

    @media (max-width:600px) {
        margin-top: 32/18 + em;
        margin-bottom: 0;
    }

    &__logo {
        color: #fff;
        flex: 0 0 grid(3);
        margin-right: grid-gap();
        margin-top: auto;
        margin-bottom: auto;

        svg {
            display: block;
            width: 200/18 + em;
            max-width: 85%;
        }
    }

    &__title {
        flex: 1; 

        h2 {
            max-width: 5em; 
        }
    }

    &__image {
        flex: 0 0 grid(5);
        margin-left: grid-gap();
        margin-top: -(80/18 + em);
        margin-bottom: -2em;

        @media (max-width:1200px) {
            margin-top: 0;
            flex: 1;
            margin-right: -6em;
        }

        @media (max-width:600px) {
            order: 4;
            margin: 0;
            margin-top: 2em;
            flex-basis: 100%;
        } 
    }

    &__text {
        margin-top: 2.25em;
        flex: 0 0 grid(6);
        margin-left: grid(3) + grid-gap(); 

        @media (max-width:700px) {
            flex-basis: grid(8);
        }

        @media (max-width:400px) {
            flex-basis: grid(12);
            margin-left: 0;
        }
    }
}