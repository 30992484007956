.coi-consent-banner__summary {
    background-color: #FFF !important;

    @media (max-width: 700px) {
        padding-bottom: .5em !important; 
        padding-top: 2em !important; 
    }
}

.coi-consent-banner__agree-button {
    background-color: var(--theme-cookie-background-color) !important;
    white-space: nowrap;

    &:hover {
        background-color: var(--theme-cookie-background-color) !important;
        box-shadow: inset 20em 20em 0 rgba(#fff, .1) !important;
    }
}

#coiConsentBanner {
    border-top: none !important;
    transition: transform .45s 5s;
    transform: translateY(100%) ;
}

#Coi-Renew {
    display:  none !important;
}

.coi-consent-banner__decline-button {
    display: none !important;
}

.summary-texts__show-details {
    text-decoration: none !important;
    opacity: .7 !important;
}

#cookie_summary.summary-texts__description {
    font-size: 1.1rem !important;
    opacity: .8 !important;
} 

.coi-consent-banner__consent-area {
    padding-bottom: 1.5em !important;
}

.summary-texts__title {
    font-weight: bold !important;
} 