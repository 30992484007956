@import "../../assets/style/mixins.scss";

.privacy-policy {

	margin-top: 128/18 + em;
	margin-bottom: 128/18 + em;

	@media (max-width:1000px) {
			margin-top: 85/18 + em;
			margin-bottom: 85/18 + em;
	}

	@media (max-width:600px) {
			margin-top: 32/18 + em;
			margin-bottom: 0;
	}

	&__list {
		list-style: decimal;
		margin-left: 1.5rem;
	}

	h1, h2, p, ol, ul, address {
		margin-bottom: 1.5rem;
	}
}