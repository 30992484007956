
.animated { 
    opacity: 0;
    left: 3em;
    position: relative;
    transition-property: opacity, left, bottom;
    transition-duration: .4s ;
    transition-timing-function: ease-out;

    @media (max-width: 900px) {
        transition: none;
        opacity: 1 !important;
        left: 0 !important;
        bottom: 0 !important;
    }

    &--a {
        transition-delay: .25s;
    }

    &--b {
        transition-delay: .4s;
    }

    &--c {
        transition-delay: .55s;
    }

    &--d {
        transition-delay: .7s;
    }

    &--e {
        transition-delay: .85s;
    }

    &--f {
        transition-delay: 1s;
    }

    &--g {
        transition-delay: 1.15s;
    }

    &--h {
        transition-delay: 1.3s;
    }
    
    &--i {
        transition-delay: 1.45s;
    }

    &--right {
        left: 3em;
    }

    &--right-small {
        left: 2rem;
    }

    &--bottom {
        bottom: -3em;
        left:0;
    }

    &--bottom-small {
        bottom: -2rem;
        left:0;
    }

    &--active {
        opacity: 1;
        left: 0;
        bottom:0;
    }
}