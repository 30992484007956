$gap: 20/1360 * 100%;
$column: 95/1360 * 100%;

@function grid($count) {
    @return $count * $column + ($count - 1) * $gap;
}

@function grid-gap() {
    @return $gap;
}

@mixin font-size($value, $min, $max) { 
    font-size: m#{i}n(m#{a}x($value, $min), $max);
}