.container {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    
    @media (max-width: 1400px + 80px) { 
        margin-left: 40px ;
        margin-right: 40px;
    }

    @media (max-width: 900px) { 
        margin-left: 20px ;
        margin-right: 20px;
    }

    @media (max-width: 450px) { 
        margin-left: 1em ;
        margin-right: 1em;
    }
}