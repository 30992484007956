@import "./mixins.scss";

body {   
    font-size: (18/16) + em;
    margin: 0; 
    font-family: var(--font-family);
    font-weight: 400;
    line-height: 1.3;
    -webkit-text-size-adjust: 100%; // iOS on orientation change 
    color: var(--theme-text-color-primary);

    @media (max-width: 1000px) { 
        font-size: 1em;
    } 
}  

.text {
    line-height: 1.4;

    &--fluffy {
        line-height: 1.5;
    }

    &--large { 
        @include font-size(4.5vw, 1.3em, 32px);
        line-height: 1.3;
    }

    &--medium { 
        @include font-size(2.5vw, 1.15em, 24px);
    }

    &--small { 
        @include font-size(1.5vw, 1em, 18px);
    }

    &--hi {
        color: var(--theme-text-color-primary);
    }

    &--lo {
        color: var(--theme-text-color-secondary);
    }

    & + & {
        margin-top: 1em;
    }
}

.h {
    line-height: 1; 
    color: var(--theme-text-color-primary);

    &--1 { 
        @include font-size(6vw, 1.85em, 50px);
    } 

		&--2 { 
			@include font-size(4.5vw, 1.3em, 32px);
	} 
}