@import "../../assets/style/mixins.scss";

.clients {
    display: flex;
    flex-wrap: wrap;

    &__title {
        flex: 0 0 grid(3);

        @media (max-width:850px) {
            flex: 0 0 grid(3);
            margin-left: grid(3) + grid-gap();
        }
    }

    &__list {
        display: flex; 
        justify-items: center;
        align-items: center;
        margin-left: 4em;
        margin-right: 4em;
        flex-wrap: wrap;
        flex: 1;

        @media (max-width:1100px) {
            margin-left: 0;
            margin-right: 0;
        }

        @media (max-width:850px) {
            flex: 0 0 grid(9);
            margin-top: 2em;
            margin-left: grid(3) + grid-gap();
        }  

        @media (max-width:700px) {
            min-height: ((34 * 2 + 65)/18 * 1em + 6em);
        }

        &__item {
            flex: 0 0 33.33%;
            text-align: center;
            height:  34/18 + em;
            color: #fff;
            animation: logo-in .3s both;
 
            &:nth-child(2) {
                animation-delay: .2s;
            }
            &:nth-child(3) {
                animation-delay: .3s;
            }

            @media (max-width:700px) {
                flex-basis: 100%; 
                text-align: left;
                margin-bottom: 2em;
            }

            &--tall {
                height: 65/18 + em;
            }

            svg {
                height: 100%;
                width: auto;
                margin-left: auto;
                margin-right: auto;

                @media (max-width:700px) {
                    margin-left: 0; 
                }
            }
        }
    }
}

@keyframes logo-in {
    from {
        opacity: 0;
        transform: translateY(-1em);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}