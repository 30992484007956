@import "../../assets/style/mixins.scss";

.get-in-touch { 
    margin-bottom: 6em;
    display: flex; 
    padding-top: 2em;
    position: relative;
    flex-wrap: wrap;
    margin-top: 7em; 
    margin-top: 8em;
    
    @media (max-width:600px) {
        margin-top: 4em;
    } 

    @media (max-width:500px) {
        padding-top: 1em;
    } 

    &__border {
        height: 4px;
        width: 100%;
        position: absolute;
        top:0;
        transition: 1.25s ease-out;
        left:0;
        transform-origin: 0 50%;
        transform: scaleX(0);
        background-color: var(--theme-border-hi-color); 
        
        @media (max-width: 900px) {
            transform: scale(1) !important; 
        }

        &--active {
            transform: scaleX(1);
        }
    }

    &__num {
        color: var(--theme-text-color-primary);   
        position: absolute;
        top: 2rem;
        left: 0;
        
        @media (max-width: 500px) {
            top: 1rem;
        }
    } 

    &__title {
        margin-left: grid(3) + grid-gap(); 
        flex: 0 0 grid(9); 

        @media (max-width: 800px) {
            max-width: 4em; 
        } 
    } 

    &__text {
        margin-left: grid(3) + grid-gap(); 
        flex: 0 0 grid(3);
        margin-top: (150/18) + em; 

        @media (max-width:1100px) {
            margin-left: grid(3) + grid-gap();
            flex-basis: grid(8);
            margin-top: (100/18) + em; 
        }

        @media (max-width:850px) { 
            margin-top: (50/18) + em; 
        }

        @media (max-width:500px) {
            flex-basis: grid(12);
            margin-left: 0;
            margin-top: (48/18) + em; 
        }
    }

    &__list {
        flex: 0 0 100%;
        margin-top: 4em;  
        display: flex;
        flex-wrap: wrap; 

        @media (max-width: 550px) {
            margin-top: 3em;
        }

        &__item {
            flex: 0 0 grid(3);
            margin-right:  grid-gap();
            border-bottom: 1px solid var(--theme-border-lo-color);
            padding-bottom: 4em; 
            color: var(--theme-address-color);

            &__email {
                line-height: 1;
                word-break: break-all;

                span{
                    //display: block;
                }
            } 

            @media (min-width: 1201px) {
                &:last-child {
                    margin-right: 0; 
                }
            }
            
            @media (max-width: 1200px) {
                flex-basis: grid(6);

                &:nth-child(even) {
                    margin-right: 0; 
                }
            }

            @media (max-width: 550px) {
                flex-basis: 100%;
                margin-right: 0;  
            }

            &__url {
                display: block;
                border-top: 1px solid var(--theme-border-hi-color);
                border-bottom: 1px solid var(--theme-border-lo-color);
                margin-bottom: 1.5em;
                padding-top: 1em;
                padding-bottom: 6em;
                position: relative;
                transition: all .2s;
                --subcolor: var(--theme-address-pre-color);
                --color: var(--theme-text-color-primary);

                @media (max-width: 850px) {
                    padding-bottom: 3em;
                }

                @media (max-width: 550px) {
                    padding-bottom: 1.5em;
                }

                &:hover {
                    background-color: #fff; 
                    border-top-color: #fff; 
                    border-bottom-color: #fff; 
                    padding-left: 1em;
                    padding-right: 1em;
                    margin-right: -1em;
                    margin-left: -1em;
                    z-index: 1;
                    --subcolor: var(--dark-blue);
                    --color: var(--dark-blue); 

                    @media (max-width: 550px) {
                        padding-left: 1.5em;
                        padding-right: 1.5em;
                        margin-right: -1.5em;
                        margin-left: -1.5em;
                    }
                }

                &__tag {  
                    color: var(--subcolor);
                    display: block;
                    margin-bottom: .5em;
                }

                &__country { 
                    color: var(--color);

                    @media (max-width: 600px) { 
                        @include font-size(6vw, 1.8em, 50px);
                    }
                }

                &__icon {
                    width: 2em;
                    position: relative;
                    top: .125em;
                    margin-left: 1em;
                    display: inline-block;
                    color: var(--color);

                    @media (max-width: 600px) { 
                        width: 1.5em;
                        top: .125em;
                    }
                }
            }
        }
    }
}