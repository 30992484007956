@import "../../assets/style/mixins.scss";

.part-two {
    margin-top: 3em;
    margin-bottom: 7em;
    display: flex; 
    padding-top: 2em;
    position: relative;
    flex-wrap: wrap;

    @media (max-width:600px) {
        margin-bottom: 4em;
        margin-top: 2em;
    }

    @media (max-width: 500px) {
        padding-top: 1em;
    }

    &__border {
        height: 4px;
        width: 100%;
        position: absolute;
        top:0;
        transition: 1.25s ease-out;
        left:0;
        transform-origin: 0 50%;
        transform: scaleX(0);
        background-color: var(--theme-border-hi-color); 
        
        @media (max-width: 900px) {
            transform: scale(1) !important; 
        }

        &--active {
            transform: scaleX(1);
        }
    }

    &__num {
        color: var(--theme-text-color-primary);   
        position: absolute;
        top: 2rem;
        left: 0;
        
        @media (max-width: 500px) {
            top: 1rem;
        }
    } 

    &__title {
        margin-left: grid(3) + grid-gap();  

        @media (max-width: 800px) {
            max-width: 4em; 
        } 
    }

    &__text {
        margin-left: grid(3) + grid-gap();
        margin-top: (150/18) + em; 

        @media (max-width:1100px) {
            margin-left: grid(3) + grid-gap();
            flex-basis: grid(8);
            margin-top: (100/18) + em; 
        }

        @media (max-width:850px) { 
            margin-top: (50/18) + em; 
        }

        @media (max-width:500px) {
            flex-basis: grid(12);
            margin-left: 0;
            margin-top: (48/18) + em; 
        }

        p {
            max-width: 35em;
        }
    }

    &__list {
        margin-left: grid(3) + grid-gap(); 
        display: flex;
        flex: 1 1 grid(9);
        margin-top: 3em; 
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid var(--theme-border-lo-color);

        @media (max-width:500px) {
            margin-top: 2em; 
            flex-basis: grid(12);
            margin-left: 0;
        }

        &::after { 
            content: "";
            display: block;
            height: 1px;
            background-color: var(--theme-border-lo-color);
            position: absolute;
            bottom: 0;
            left:0;
            width: grid(3);

            @media (max-width:700px) {
                display: none;
            }
        }

        &__item {
            flex: 0 0 44%;
            box-sizing: border-box;
            padding-left: 10%;
            margin-bottom: 4.5em;
            position: relative;
            border-top: 1px solid var(--theme-border-lo-color);
            padding-top: 1em;

            @media (max-width:850px) {
                flex-basis: 45%;
                margin-left: 0;
            }

            @media (max-width:700px) {
                flex-basis: 100%;
                margin-left: 0;
                margin-bottom: 3.5em;
                padding-left: 15%;
            }

            &__i {
                position: absolute;
                top: .5rem;
                left: 0;
                line-height: 1;
                @include font-size(8vw, 1.2em, 70px);
                color: #FFF;

                @media (max-width:600px) {
                    top: 1rem;
                }
            }

            &__title {
                font-weight: bold;
                margin-bottom: 1em;
                color: var(--theme-subtitle-color);

                &::after {
                    display: block;
                    margin-top: 1em;
                    content: "";
                    height: 2px;
                    width: 1.5em;
                    background-color: currentColor;
                }
            }
        }
    }
}