@import "../../assets/style/mixins.scss";

.footer {
    position: relative; 
    padding-top: 11em;
    padding-bottom: 11em;
    margin-top: 19em; 

    @media (max-width: 1000px) {
        margin-top: 14em; 
    }

    @media (max-width: 600px) {
        margin-top: 10em; 
    }

    &__inner {
        display: flex; 
        flex-wrap: wrap;
        align-items: center;

        button {
            z-index: 100;
            position: relative;
        }
    }

    &__logo {
        flex: 0 0 grid(3);
        margin-right: grid-gap();

        svg {
            max-width: 85%;
        }   
    }

    &__hook {
        color: var(--theme-text-color-secondary); 
        max-width: 6.5em;
    }  

    &__copy {
        flex: 0 0 grid(6);
        margin-top: 3em;
        margin-left: grid(3) + grid-gap();

				&-link {
					text-decoration: underline;
				}
    }

    &__background {
        position: absolute; 
        overflow: hidden;
        color: var(--theme-graphic-color);  
        bottom:0;
        height: 100%;
        width: 100%; 
        left:0; 
				pointer-events: none;

        &--spline {
            height: 130%;
        }
    }
}