@import "../../assets/style/mixins.scss";

.noa-family {
    margin-top: 6em;
    margin-bottom: 6em;
    position: relative; 

    &__title {
        margin-bottom: 160/18 + em;
        color: #FFF;
        
        @media (max-width: 1000px) {
            margin-bottom: 60/18 + em; 
        }

        @media (max-width:600px) {
            margin-bottom: 32/18 + em; 
        }

        strong {
            font-weight: bold;
        }
    }

    &__text {
        color: #FFF;
    }

    &__background {
        margin-left: auto;
        margin-right: auto;
        max-width: 920px;
        display: block;
        z-index: 1;
    }

    &__content {
        position: absolute;
        width: 688px;
        max-width: 80%;
        top: 50%;
        z-index: 2;
        left:50%;
        transform: translate(-50%, -50%);
    }
}