:root {
    --dark-blue: #051930;
    --sexy-red: #DA534D; 
    --beige: #DCD2C3;
    --light-blue: #D3E5E6;

    --font-family: "Aften Screen", Helvetica, sans-serif; 

    --theme-background-color: var(--dark-blue);  
    --theme-text-color-primary: #FFF;
    --theme-text-color-secondary: var(--beige);
    --theme-graphic-color: var(--light-blue);
    --theme-address-color: #FFF;
    --theme-address-pre-color: var(--theme-text-color-secondary); 
    --theme-subtitle-color: var(--theme-text-color-primary); 
    --theme-hero-logo-color: #fff;  
    --theme-hero-title-color: var(--theme-text-color-secondary);  
    --theme-border-hi-color: var(--light-blue);
    --theme-border-lo-color: rgba(211, 229, 230, 0.3);
    --theme-cookie-background-color: var(--dark-blue);
}